import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assets/logo5.jpeg';
import Name from '../Assets/name.png';

export default function NavBar() {
  const [showMyWorkMenu, setShowMyWorkMenu] = useState(false);

  const toggleMyWorkMenu = () => {
    setShowMyWorkMenu(!showMyWorkMenu);
  };

  return (
    <nav className="navbar">
      <Link to='/'>
        <div className="logo">
          <img className="w-20 h-auto" src={Logo} alt="Logo" />
        </div>
      </Link>
      <div className='name-text'>
        <img src={Name} alt="name"/>
      </div>
      <div className="links">
        <Link to="/">Home</Link>
        <Link
          onMouseEnter={toggleMyWorkMenu}
          onMouseLeave={toggleMyWorkMenu}
          className="has-dropdown"
        >
          My Work
          {showMyWorkMenu && (
            <div className="dropdown-content">
              <Link to="/portfolio/rise">Rise</Link>
              <Link to="/portfolio/speakeasy">SpeakEasy</Link>
              <Link to="/portfolio/Link">Link</Link>
              <Link to="/portfolio/war">WAR</Link>
            </div>
          )}
        </Link>
        <Link to="/about">About Me</Link>
        <Link to="/resume">Resume</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </nav>
  );
}
