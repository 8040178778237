import React, {useEffect} from 'react';
import LinkImage from '../../../components/Assets/link.png';

export default function LinkDetail() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount in class components

  const details = [
    {
      name: 'The [L]ink',
      image: LinkImage,
      snapshot:
        'Your compass to extraordinary tattoos! Navigate through a curated directory, find the perfect artist, and wear your uniqueness with pride.',
      stack: [
        'JavaScript',
        'Mongoose',
        'Express',
        'React',
        'Node.js',
        'Vanilla CSS',
      ],
      tldr: ['Security', 'User Experience', 'Full-Stack'],
      site: 'https://the-tattoo-link.herokuapp.com/',
      desc: "I created this full-stack project from scratch by curating and organizing seed data, ultimately creating a dynamic aggregator. This empowered users with a sophisticated filtering system, enabling them to discover artists based on distinct criteria such as location, reviews, and specialty. Through meticulous data compilation and the implementation of an innovative filtering mechanism, I focused enhancing the user experience. This initiative not only showcased my attention to detail and technical proficiency but also directly contributed to the platform's usability and accessibility for both users and artists.",
      desc2: "In addition to this, I implemented a robust review system where users can seamlessly post, edit, and delete reviews based on their unique experiences with artists. Upholding the highest standards of security, I ensured that users could create accounts securely and that they have exclusive control over the reviews they post. This intricate security framework not only safeguards user data but also allows for a personalized and trustworthy user experience."
    },
  ];

  return (
    <div className="detail-card h-full">
      {details.map((detail, index) => (
        <div key={index} className="detail-row">
          <div className="detail-col-l">
            <img className="min-w-[333px] mt-14" src={detail.image} alt={detail.name} />
          </div>
          <div className="detail-col-r mb-8">
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%]">
                Toolbox
              </h2>
              <ul className='stack-container'>
                {detail.stack.map((tech, i) => (
                  <li className='skill' key={i}>{tech}</li>
                ))}
              </ul>
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%] mt-[45px] pb-1">
                Highlights
              </h2>
              <ul className='tldr-container'>
                {detail.tldr.map((tech, i) => (
                  <li className='tldr' key={i}>{tech}</li>
                ))}
              </ul>
            <div className="desc-container">
                <h1 className="app-name-header">{detail.name}</h1>
                <h2 className='desc-header mt-4'>Solo Full Stack Developer</h2>
            <div>
              <p className='desc'>{detail.desc}</p>
              <p className='desc desc2'>{detail.desc2}</p>
            </div>
              <h3 className='snapshot'>{detail.snapshot}</h3>
              <div className='live-container'>
              <a
                href={detail.site}
                target="_blank"
                rel="noopener noreferrer"
                className="live-button"
              >
                Live Site
              </a>
            </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
