import React from "react";
import Resume from '../../components/Assets/resume.png'

export default function ResumePage() {
  return (
    <div>
    <div className="resume">
    <img src={Resume} alt='resume'></img>
    </div>
    </div>
  )
}