import React from "react";
import Britt from '../Assets/britt1.jpg'

export default function BrittBox () {
    return (
        <div className="green-container">
            <div className="image-and-box">
                <img src={Britt} alt="Brittany" className="image" />
                <div className="green-box"></div>
            </div>
        </div>
    );
};
