import React, {useEffect} from 'react';
import Contact from '../../components/Assets/Contact.png';

export default function ContactPage() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount in class components

  return (
    <div className="flex items-center">
      <div className="w-1/2 ml-[100px] p-10">
        <h1 className="contact-header text-themeCyan text-3xl mb-4">Get in Touch</h1>
        <img className="contact-image" src={Contact} alt="contact-logo" />
      </div>
      <div className="w-1/2 p-10 mr-[80px]">
        <h1 className='in-touch-text'>The best way to get in touch with me at this time is via my email address - <strong className='email'>bc.stidd.dev@gmail.com</strong></h1>
        <h2 className='in-touch-text2'>I look forward to hearing from you!</h2>
      </div>
    </div>
  );
}
