import React, {useEffect} from 'react';
import RiseImage from '../../../components/Assets/rise.png';

export default function RiseDetail() {
  const details = [
    {
      name: 'Rise',
      image: RiseImage,
      snapshot:
        'Elevate your financial prowess with the mobile app, Rise - merging credit insights and contemporary financial wisdom for a brighter financial journey.',
      stack: [
        'JavaScript',
        'React Native',
        'Tailwind CSS',
        'Expo',
        'Supabase',
        'Figma',
      ],
      tldr:['Cross-Functional Teams', 'Full-Stack', 'Built in 3 Days'],
      desc:'Introducing Rise, a sleek mobile app born from a rapid financial literacy hackathon. Built for easy accessibility and user-friendliness, Rise aims to boost credit literacy across all age groups. It features a user-friendly interface, personalized profiles, and leverages technologies like React Native, JavaScript, and Expo. With secure data management powered by Supabase, Rise is the collaborative effort of skilled UX designers, software developers, and data engineers, coming to life within a three-day sprint. Glide into the future of financial literacy effortlessly with Rise—where innovation meets education.',
      desc2:'My role within the project was as the lead of front-end development and design. I was responsible for maintaining open and concise communication across our team to reach our goals via development.',
      site: 'https://github.com/Personal-Finance-Credit-Score/creditapp/blob/main/README.md',
    },
  ];
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount in class components

  return (
    <div className="detail-card h-full">
      {details.map((detail, index) => (
        <div key={index} className="detail-row">
          <div className="detail-col-l">
            <img className="min-w-[333px] mt-14" src={detail.image} alt={detail.name} />
          </div>
          <div className="detail-col-r mb-8">
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%]">
                Toolbox
              </h2>
              <ul className='stack-container'>
                {detail.stack.map((tech, i) => (
                  <li className='skill' key={i}>{tech}</li>
                ))}
              </ul>
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%] mt-[45px] pb-1">
                Highlights
              </h2>
              <ul className='tldr-container'>
                {detail.tldr.map((tech, i) => (
                  <li className='tldr' key={i}>{tech}</li>
                ))}
              </ul>
            <div className="desc-container">
                <h1 className="app-name-header">{detail.name}</h1>
                <h2 className='desc-header mt-4'>Front-End Developer, Hackathon</h2>
            <div>
              <p className='desc'>{detail.desc}</p>
              <p className='desc desc2'>{detail.desc2}</p>
            </div>
              <h3 className='snapshot'>{detail.snapshot}</h3>
              <div className='live-container'>
              <a
                href={detail.site}
                target="_blank"
                rel="noopener noreferrer"
                className="live-button"
              >
                Live Site
              </a>
            </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
