import './index.css';
import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import Title from '../components/Title/Title';
import NavBar from '../components/NavBar/NavBar';
import HomePage from '../pages/HomePage/HomePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import WorkPage from '../pages/WorkPage/WorkPage';
import ResumePage from '../pages/ResumePage/ResumePage';
import ContactPage from '../pages/ContactPage/ContactPage';
import Footer from '../components/Footer/Footer';

// Project Detail Pages
import RiseDetail from '../pages/WorkPage/ProjectDetail/RiseDetail';
import SpeakEasyDetail from '../pages/WorkPage/ProjectDetail/SpeakEasyDetail';
import LinkDetail from '../pages/WorkPage/ProjectDetail/LinkDetail';
import WarDetail from '../pages/WorkPage/ProjectDetail/WarDetail';

export default function App() {
  //route configuration
  const routes = [
    { path: '/', element: <HomePage /> },
    { path: '/home', element: <HomePage /> },
    { path: '/about', element: <AboutPage /> },
    { path: '/portfolio', element: <WorkPage /> },
    { path: '/contact', element: <ContactPage /> },
    { path: '/resume', element: <ResumePage /> },
    { path: '/portfolio/Rise', element: <RiseDetail /> },
    { path: '/portfolio/Link', element: <LinkDetail /> },
    { path: '/portfolio/SpeakEasy', element: <SpeakEasyDetail /> },
    { path: '/portfolio/War', element: <WarDetail /> },
  ];

  return (
    <div>
      <Title>Brittany Stidd - Portfolio</Title>
    <main className="bg-themeCream h-100%">
      <NavBar />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
    </main>
    </div>
  );
}
