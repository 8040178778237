import React, { useEffect } from 'react';
import about from '../../components/Assets/about.png';
import Finn from '../../components/Assets/Finn.png';

const hobbies = [
  'Cooking', 'Exploring the outdoors', 'Traveling and Roadtripping', 'Reading', 'Spending time with my pets', 'Camping', 'Practicing yoga', 'Fishing',
];

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 md:mx-16">
        {/* Left Column */}
        <div className="col-span-1 md:col-span-1 max-w-screen-xl md:max-w-[800px] md:mx-auto">
          <div className="bg-themeCream p-8 mt-8 ml-[160px]">
            <h1 className="text-themeCyan mt-[130px] md:mt-8 sm:mt-8 text-5xl font-medium font-AvenirNext mb-4">
              Hello!
            </h1>
            <p className='mt-[50px]'>
              With more than 10 years in the game as a Full-Stack Developer and Senior Maxillofacial Surgical Assistant, I'm all about the joy of untangling problems and consistently bringing my A-game.
            </p>
            <br />

            <div className='about-highlight'>
              <span className='background-span'>As a developer, </span>
            </div>
            <p className="inline">
              it's the thrill of problem-solving that keeps me going, always pushing to create top-notch solutions.
            </p>
            <br />
            <br />
            <div className='about-highlight'>
              <span className='background-span'>As a human,</span>
            </div>
            <p className="inline">
              I'm the kind of colleague who brings a mix of fun and approachability to the table. I believe in leading with emotional intelligence and a healthy dose of humility.
            </p>
            <br />
            <br />
            <p><i>Let's make things happen and enjoy the ride together! </i></p>
            <br />
            <br />
            <br />
            <a href="/contact" className="button md:mt-4">
              GET IN TOUCH
            </a>
            <h2 className="text-themeBlack text-[30px] p-[20px] mt-[135px] self-center font-light font-Aven irNext w-full md:w-[1375px] md:ml-auto">
              "So often in life, things that you regard as an impediment turn out to be great, good fortune."
            </h2>
            <h3 className="author mt-2 md:mt-4">— Ruth Bader Ginsburg</h3>
            <div className="about-col-left-2">
              <img className="mr-[315px] mt-[130px] md:mr-0 md:mt-8" src={Finn} alt="finn"></img>
            </div>
            <br />
          </div>
        </div>

        {/* Right Column */}
        <div className="col-span-1 md:col-span-1 text-center">
          <img className="mr-[315px] mt-[150px] md:mr-0 md:mt-8 sm:mr-0" src={about} alt="Brittany" />
          <div className="mt-5 ml-4">
            <h2 className='hobbies-header'>Outside of work - I love:</h2>
            <ul className="hobbies-container">
              {hobbies.map((hobby, index) => (
                <li
                  key={index}
                  className="hobbies text-themeWhite text-lg font-AvenirNext mb-2 w-[100px] p-2 rounded">
                  {hobby}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
