import React, { useEffect } from 'react';

const Title = ({ children }) => {
  useEffect(() => {
    document.title = children || 'Brittany Stidd - Portfolio'; // Set the document title to the provided title or 'React App'
  }, [children]);

  return null; // The component doesn't render anything to the DOM
};

export default Title;