import React, { useEffect } from 'react';
import mountain from '../../components/Assets/mountains.png';
import BrittBox from '../../components/GreenBox/BrittBox';
import WorkPage from '../../pages/WorkPage/WorkPage';
import Git from '../../components/Assets/github2.png';
import LinkedIn from '../../components/Assets/linkedin.png';
import hrc from '../../components/Assets/hrclogo.jpg';

const skills = [
  'Javascript', 'Python3', 'HTML', 'CSS', 'React Native', 'Typescript',
  'Node.js', 'jQuery', 'Mongoose', 'Django', 'Supabase',
  'MongoDB', 'PostgreSQL', 'SQL',
];

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <img src={mountain} alt="mountains" className='mountains' />
      <div className="relative grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 md:mx-16">
        {/* Left Column */}
        <div className="bg-themeCream p-8 mt-8 md:mt-0">
          <h1 className="text-themeCyan text-5xl font-medium font-AvenirNext mb-4">
            Hi, I’m Brittany |
          </h1>
          <h1 className="text-themeGreen text-5xl font-medium font-AvenirNext mb-4">
            Software Engineer
          </h1>
          <h2 className="text-themeCyan text-2xl m-3 mt-8 font-medium font-AvenirNext">
            My Skills
          </h2>
          <ul className="skills-container">
            {skills.map((skill, index) => (
              <li
                key={index}
                className="skill"
              >
                {skill}
              </li>
            ))}
          </ul>
          <p className="text-themeBlack text-[18px] font-roboto mt-8">
            I'm a full-stack software engineer from Portland, Oregon. I thrive in optimizing efficiency, crafting features, and creating intuitive UI experiences. With over a decade as a Senior Surgical Assistant, I bring meticulous detail and consistent excellence to my development work. Beyond code, my emotional intelligence makes me approachable, fun, and patient. I look forward to connecting!
          </p>
          <br />
          <br />
          <a href="/about" className="button mt-8">
            GET TO KNOW ME
          </a>
          <br />
          <br />
        </div>

        {/* Right Column */}
        <div className="text-center mt-8 md:mt-0">
          <BrittBox />
          <div className='icons'>
            <div className='contact-icons'>
              <a href="https://github.com/bcstidd" target="_blank" rel="noopener noreferrer">
                <img
                  className='git'
                  src={Git}
                  alt="GitHub Logo"
                />
              </a>
              <a href="https://www.linkedin.com/in/brittany-stidd/" target="_blank" rel="noopener noreferrer">
                <img
                  className='linkedin'
                  src={LinkedIn}
                  alt="LinkedIn Logo"
                />
              </a>
              <a href="https://www.hrc.org/resources/communities-of-color" target="_blank" rel="noopener noreferrer">
                <img
                  className='hrc'
                  src={hrc}
                  alt="HRC Logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <WorkPage />
    </>
  );
}
