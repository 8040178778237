import React from 'react';
import { Link } from 'react-router-dom';
import github from '../Assets/github2.png';
import linkedin from '../Assets/linkedin.png';
import hrc from '../Assets/hrclogo.jpg'
export default function Footer() {
  return (
    <div>
      <footer className="footer">
       <Link to='contact'><div className='contact-text'>Let's work together!</div></Link> 
       <div className='icons'>
              <a href="https://github.com/bcstidd" target="_blank" rel="noopener noreferrer">
                <img
                  className='git'
                  src={github}
                  alt="GitHub Logo"
                />
              </a>
              <a href="https://www.linkedin.com/in/brittany-stidd/" target="_blank" rel="noopener noreferrer">
                <img
                  className='linkedin'
                  src={linkedin}
                  alt="LinkedIn Logo"
                />
              </a>
              <a href="https://www.hrc.org/resources/communities-of-color" target="_blank" rel="noopener noreferrer">
                <img
                  className='hrc'
                  src={hrc}
                  alt="GitHub Logo"
                />
              </a>
            </div>
        <h5 className="footer-text">bc.stidd.dev@gmail.com</h5>
      </footer>
    </div>
  );
}
