import React, {useEffect} from 'react';
import SpeakEasyImage from '../../../components/Assets/speakeasy.png';

export default function SpeakEasyDetail() {
  const details = [
    {
      name: 'SpeakEasy',
      image: SpeakEasyImage,
      snapshot:
        'Unlock a world of languages together; a social translation app where users collaborate to master native tongues and break communication barriers.',
      stack: [
        'Python',
        'Django',
        'Google API',
        'HTML5',
        'CSS3',
        'Bootstrap',
        'PostgreSQL',
        'Boto3',
        'Figma'
      ],
      tldr:['Full-Stack', 'Secure Profiles', 'Custom Logo'],
      desc:'As the lead front-end developer and designer, I played a key role in the build of this immersive platform. Users can submit phrases, receive feedback, and personalize their profiles, enhancing their language-learning journey. Utilizing technologies like Google Translate API, Python, Django, HTML5, CSS3, and Bootstrap, our team crafted a scalable and user-friendly experience. I even made the logo!',
      desc2:'Explore SpeakEasy and witness the seamless blend of design, functionality, and a shared passion for linguistic exploration. Our stretch goals include improving auto-translate functionality, enhancing design, and enabling personalized profiles with image uploads.',
      site: 'https://github.com/bcstidd/thespeakeasyapp',
    },
  ];
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount in class components

  return (
    <div className="detail-card h-full">
      {details.map((detail, index) => (
        <div key={index} className="detail-row">
          <div className="detail-col-l">
            <img className="min-w-[333px] mt-14" src={detail.image} alt={detail.name} />
          </div>
          <div className="detail-col-r">
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%]">
                Toolbox
              </h2>
              <ul className='stack-container'>
                {detail.stack.map((tech, i) => (
                  <li className='skill' key={i}>{tech}</li>
                ))}
              </ul>
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%] mt-[35px] pb-1">
                Highlights
              </h2>
              <ul className='tldr-container'>
                {detail.tldr.map((tech, i) => (
                  <li className='tldr' key={i}>{tech}</li>
                ))}
              </ul>
            <div className="desc-container">
                <h1 className="app-name-header">{detail.name}</h1>
                <h2 className='desc-header mt-4'>Front-End Developer, Designer</h2>
            <div>
              <p className='desc'>{detail.desc}</p>
              <p className='desc desc2'>{detail.desc2}</p>
            </div>
              <h3 className='snapshot'>{detail.snapshot}</h3>
              <div className='live-container'>
              <a
                href={detail.site}
                target="_blank"
                rel="noopener noreferrer"
                className="live-button"
              >
                Live Site
              </a>
            </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

