import React from 'react';
import { Link } from 'react-router-dom';
import Linkpic from '../../components/Assets/link.png'
import Rise from '../../components/Assets/rise.png';
import SpeakEasy from '../../components/Assets/speakeasy.png';
import War from '../../components/Assets/war.png';

export default function WorkPage() {
  const projects = [
    {
      name: 'Rise',
      image: Rise,
      description:
        'Elevate your financial prowess with the mobile app, Rise - merging credit insights and contemporary financial wisdom for a brighter financial journey.',
      tools: ['JavaScript', 'React Native', 'Figma', 'Tailwind CSS', 'Expo', 'SupaBase'], 
    },
    {
      name: 'SpeakEasy',
      image: SpeakEasy,
      description:
        'Unlock a world of languages together; a social translation app where users collaborate to master native tongues and break communication barriers.',
        tools: ['Python', 'Django', 'GoogleTranslateAPI', 'HTML5', 'CSS', 'Bootstrap', 'PostgreSQL', 'Boto3', 'Figma'], 
      },
    {
      name: 'Link',
      image: Linkpic,
      description: 'Your compass to extraordinary tattoos! Navigate through a curated directory, find the perfect artist, and wear your uniqueness with pride.',
      tools: ['JavaScript', 'React', 'Express', 'MongooseODM', 'node.js', 'Vanilla CSS'], 
    },
    {
      name: 'War',
      image: War,
      description:
        'Journey back to your childhood with a classic card game War, revitalized for the digital age.',
      tools: ['JavaScript', 'HTML5', 'Vanilla CSS']  
    },
  ];

  return (
    <div className="bg-themeCream min-h-screen">
      <h1 className="text-[53px] self-center text-center mx-auto text-themeCyan border-b-2 border-b-themeGreen w-[250px] mb-[100px] flex-row">Projects</h1>
      {projects.map((project) => (
        <div
          key={project.name}
          className="p-4 mb-[130px] mb-4 flex flex-col items-center md:flex-row bg-themeCream"
        >
          <div className="w-full md:w-1/4 ml-[200px] mr-[125px]">
            <img src={project.image} alt={project.name} className="project-img" />
          </div>
          <div className="w-full md:w-1/2 p-4">
            <h2 className="text-xl font-semibold mb-3">{project.name}</h2>
            <p>{project.description}</p>
            <br />
            <ul className="tools-container" >
              {project.tools.map((tool, index) => (
                <li key={index} className="tool">
                  {tool}
                </li>
              ))}
            </ul>
            <Link to={`/portfolio/${project.name}`} className='more-button'>
              VIEW PROJECT
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
