import React, {useEffect} from 'react';
import WarImage from '../../../components/Assets/war.png';

export default function WarDetail() {
  const details = [
    {
      name: 'War',
      image: WarImage,
      snapshot:
        'Journey back to your childhood with a classic card game War, revitalized for the digital age.',
      stack: ['JavaScript', 'HTML5', 'Vanilla CSS'],
      tldr:['Algorithms', '"Win/Loss Logic"', 'Simplicity', 'Styling'],
      desc:'Dive into the digital adaptation of the classic card game War, where my utilization of HTML, CSS, and JavaScript not only resulted in a game boasting intelligent win/loss logic but also served as a significant milestone in deepening my understanding of JavaScript algorithms. The user interface, thoughtfully crafted with HTML and CSS, seamlessly blends style and simplicity, offering players an engaging and user-friendly experience. Infusing mathematical concepts into the gameplay elevates its sophistication, showcasing a harmonious integration of creativity and technical skill. ',
      desc2:'',
      site: 'https://bcstidd.github.io/WAR/',
    },
  ];
  //to top by default
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="detail-card h-full">
      {details.map((detail, index) => (
        <div key={index} className="detail-row">
          <div className="detail-col-l">
            <img className="min-w-[333px] mt-14" src={detail.image} alt={detail.name} />
          </div>
          <div className="detail-col-r mb-14">
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%]">
                Toolbox
              </h2>
              <ul className='stack-container'>
                {detail.stack.map((tech, i) => (
                  <li className='skill' key={i}>{tech}</li>
                ))}
              </ul>
              <h2 className="font-medium border-b-2 border-themeGreen w-[15%] mt-[45px] pb-1">
                Highlights
              </h2>
              <ul className='tldr-container'>
                {detail.tldr.map((tech, i) => (
                  <li className='tldr' key={i}>{tech}</li>
                ))}
              </ul>
            <div className="desc-container">
                <h1 className="app-name-header">{detail.name}</h1>
                <h2 className='desc-header mt-4'>Solo Developer</h2>
            <div>
              <p className='desc'>{detail.desc}</p>
              <p className='desc desc2'>{detail.desc2}</p>
            </div>
              <h3 className='snapshot'>{detail.snapshot}</h3>
              <div className='live-container'>
              <a
                href={detail.site}
                target="_blank"
                rel="noopener noreferrer"
                className="live-button"
              >
                Live Site
              </a>
            </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
